var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "graphTitle",
        { staticClass: "title", attrs: { title: "停车时长分析" } },
        [
          _c("el-tooltip", { attrs: { placement: "top-start" } }, [
            _c("div", { attrs: { slot: "content" }, slot: "content" }, [
              _vm._v(
                " 停车次数：展示统计期间内，各个停车时长区间内的停车记录数"
              ),
              _c("br"),
              _vm._v(
                " 停车时长：展示统计时间段内，在各个停车时长区间内的停车记录的总时长。 "
              ),
            ]),
            _c("i", { staticClass: "el-icon-question my-icon" }),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "cylindricalchart", attrs: { id: "chart" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }